import React from "react"

const Avatar = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className={`avatar avatar-placeholder ${
        !!props.size && `avatar-${props.size}`
      } bg-gray-300 bg-cover bg-center bg-no-repeat rounded-lg
      ${!!props.css && props.css}
      `}
      style={{
        backgroundImage: `url(${props.src})`,
      }}
    ></div>
  )
})

export default Avatar
