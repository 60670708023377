import React from "react";
import { withRouter } from "react-router";
import arrow from "../../images/arrow-go-back.svg";

const BackButton = (props) => {
  return (
    <div
      className="flex items-center cursor-pointer hover:opacity-50"
      onClick={() => props.history.goBack()}
    >
      <img src={arrow} alt="" className="mr-4" />
      {props.children}
    </div>
  );
};

export default withRouter(BackButton);
