import React from "react"

const FormTitle = (props) => {
  return (
    <div className={`text-3xl font-bold ${!!props.noMargin ? "" : "mb-10"}`}>
      {props.children}
    </div>
  )
}

export default FormTitle
