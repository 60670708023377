import React from "react"
import { Button, BackButton } from "./../atoms"

const PageTitle = (props) => {
  const buttonsData = []

  const DisplayButtons = () => {
    const buttons = props.buttons

    if (Array.isArray(buttons) && buttons.length > 0) {
      const totalButtons = buttons.length

      return (
        <div className="ml-auto pt-8 lg:pt-0">
          {buttons.map((btn, index) => {
            if (!!btn.label) {
              let variant = btn.variant
              let disabled = btn.disabled
              let paddingX = btn.paddingX
              let paddingY = btn.paddingY

              if (!btn.variant && totalButtons !== 1) {
                switch (index) {
                  case 0:
                    variant = "green"
                    break
                  default:
                    break
                }
              }

              return (
                <Button
                  key={index}
                  variant={variant}
                  disabled={disabled}
                  paddingX={paddingX}
                  paddingY={paddingY}
                  onClick={btn.onClick}
                  css={`
                    ${totalButtons !== 1 && "sm:ml-3"} ${index > 0 &&
                    "mt-2 sm:mt-0"}
                  `}
                >
                  {btn.label}
                </Button>
              )
            }
          })}
        </div>
      )
    }
    return null
  }

  return (
    <div className="sm:flex flex-wrap items-center justify-between mb-10">
      {(props.backButton === true && (
        <div className="flex items-center">
          <BackButton>
            <div className="text-2xl md:text-3xl font-bold">
              {props.children}
            </div>
          </BackButton>
        </div>
      )) || (
        <div className="text-2xl md:text-3xl font-bold">{props.children}</div>
      )}
      <DisplayButtons />
    </div>
  )
}

export default PageTitle
