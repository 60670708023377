import React from "react";
import { withRouter } from "react-router-dom";
import { _matchRouteSlug } from "../../utils/helper";
import { fakeModalScreens } from "../../utils/navigationLinks";

const Container = (props) => {
  const showFakeModal = _matchRouteSlug(
    fakeModalScreens,
    props.location.pathname
  );

  return (
    <div
      className={`${
        showFakeModal === true &&
        "bg-outerSpace min-h-screen flex items-center justify-center"
      } ${!!props.css && props.css}`}
    >
      <div className="container mx-auto px-4">{props.children}</div>
    </div>
  );
};

export default withRouter(Container);
