import React from "react"

const DataRow = (props) => {
  const flexRowScreen = props.flexRowScreen || "lg"
  const rowSize = props.rowSize

  let defaultClass

  switch (rowSize) {
    case "small":
      defaultClass = `bg-white flex flex-col ${flexRowScreen}:flex-row justify-between items-center -mx-4 
      ${flexRowScreen}:mx-0 px-4 ${flexRowScreen}:px-5 py-3 z-20 relative border-b 
      ${props.isLastItem ? "border-transparent rounded-b-md" : "border-mischka"}
      ${props.isFirstItem ? "rounded-t-md" : ""}
      `
      break

    default:
      defaultClass = `bg-white flex flex-col ${flexRowScreen}:flex-row justify-between items-center 
      ${flexRowScreen}:min-h-28 -mx-4 ${flexRowScreen}:mx-0 px-4 ${flexRowScreen}:px-5 py-3 mb-2 z-20 relative 
      rounded shadow-md`
      break
  }

  return <div className={defaultClass}>{props.children}</div>
}

export default DataRow
