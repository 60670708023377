import React from "react"

const Button = React.forwardRef((props, ref) => {
  let buttonClass

  if (props.disabled === true) {
    buttonClass = "bg-silver border-silver text-white opacity-50"
  } else {
    switch (props.variant) {
      case "green":
        buttonClass = "bg-citron border-citron text-white"
        break

      case "hollow":
        buttonClass = "bg-white border-mischka text-oxfordBlue"
        break

      case "white":
        buttonClass = "bg-white border-white text-oxfordBlue"
        break

      case "dark":
        buttonClass = "bg-oxfordBlue border-oxfordBlue text-white"
        break

      default:
        buttonClass = "bg-fountainBlue border-fountainBlue text-white"
        break
    }
  }

  return (
    <button
      ref={ref}
      className={`rounded-md border font-medium w-full sm:w-auto flex-shrink hover:opacity-50 transition-all ease-in-out 
      ${(!!props.paddingY && props.paddingY) || "py-5"} 
      ${(!!props.paddingX && props.paddingX) || "px-10"} 
      ${!!buttonClass && buttonClass}
      ${!props.disableMinWidth && "min-w-180px"} 
      ${props.disabled === true && "pointer-events-none"} 
      ${!!props.css && props.css}`}
      type={props.type ? props.type : "button"}
      onClick={
        props.disabled
          ? () => {
              return
            }
          : props.onClick
      }
      disabled={props.disabled}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </button>
  )
})

export default Button
