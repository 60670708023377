import api from "./../../utils/api"

const pathURL = "auth"

export const login = (params) => {
  const url = `${pathURL}/login`
  return api.post(url, params)
}

export const verifyLogin2FA = (params) => {
  const url = `2fa/${params.user_id}/verify`
  return api.post(url, params.fields)
}

export const logout = () => {
  const url = `${pathURL}/logout`
  return api.get(url)
}
