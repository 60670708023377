import * as actionTypes from "../actions/actionTypes"
import produce from "immer"
import _ from "lodash"

const INITIAL_STATE = {
  user_id: null,
  access_token: null,
  forgotPasswordEmail: "",
  resetPasswordSuccess: false,
  forgotPassword: { isSubmitted: false, error: {} },
  userLogin: {
    isSubmitted: false,
    error: {},
  },
  is_2FA_enabled: false,
  is_2FA_login_success: false,
  login2FA: {
    isSubmitted: false,
    error: {},
  },
  role: null,
}

export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Login
    case actionTypes.REQUEST_LOGIN: {
      return produce(state, (draft) => {
        draft.userLogin.isSubmitted = false
        draft.userLogin.error = false
        draft.access_token = null
      })
    }
    case actionTypes.SUCCESS_LOGIN: {
      return produce(state, (draft) => {
        draft.userLogin.isSubmitted = true
        draft.userLogin.error = false
        draft.access_token = action.data.token
        draft.role = action.data.role
        draft.user_id = action.data.user_id
        draft.is_2FA_enabled = action.data.status_2fa
      })
    }
    case actionTypes.FAIL_LOGIN: {
      return produce(state, (draft) => {
        draft.userLogin.isSubmitted = true
        if (_.isObject(action.data.errors) && !_.isEmpty(action.data.errors)) {
          draft.userLogin.error = action.data.errors
        } else {
          draft.userLogin.error = true
        }
        draft.access_token = null
        draft.role = null
        draft.is_2FA_enabled = false
      })
    }
    // 2FA
    case actionTypes.REQUEST_VERIFY_LOGIN_2FA:
      return produce(state, (draft) => {
        draft.login2FA.isSubmitted = false
        draft.login2FA.error = false
        draft.is_2FA_login_success = false
      })
    case actionTypes.SUCCESS_VERIFY_LOGIN_2FA:
      return produce(state, (draft) => {
        draft.login2FA.isSubmitted = true
        draft.login2FA.error = false
        draft.is_2FA_login_success = true
      })
    case actionTypes.FAIL_VERIFY_LOGIN_2FA:
      return produce(state, (draft) => {
        draft.login2FA.isSubmitted = true
        if (_.isObject(action.data.error) && !_.isEmpty(action.data.error)) {
          draft.login2FA.error = action.data.error
        } else {
          draft.login2FA.error = true
        }
        draft.is_2FA_login_success = false
      })
    // Logout
    case actionTypes.SUCCESS_LOGOUT:
      return produce(state, (draft) => {
        draft.access_token = null
        draft.role = null
        draft.is_2FA_enabled = false
        draft.is_2FA_login_success = false
      })
    // Forgot Password
    case actionTypes.SUCCESS_FORGOT_PASSWORD:
      return produce(state, (draft) => {
        draft.forgotPasswordEmail = action.data.email
      })
    case actionTypes.FAIL_FORGOT_PASSWORD:
    case actionTypes.CLEAR_FORGOT_PASSWORD:
      return produce(state, (draft) => {
        draft.forgotPasswordEmail = ""
        draft.forgotPassword.isSubmitted = true
        draft.forgotPassword.error = action.data
      })
    // Reset Password
    case actionTypes.SUCCESS_RESET_PASSWORD:
      return produce(state, (draft) => {
        draft.resetPasswordSuccess = true
      })
    case actionTypes.FAIL_RESET_PASSWORD:
      return produce(state, (draft) => {
        draft.resetPasswordSuccess = false
      })
    case actionTypes.CLEAR_RESET_PASSWORD:
      return produce(state, (draft) => {
        draft.resetPasswordSuccess = false
      })

    default:
      return state
  }
}
