import React from "react"

const TextLink = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      className={`underline font-medium transition-all ease-in-out ${
        props.disabled
          ? "text-silver opacity-75"
          : "text-fountainBlue hover:opacity-50"
      }
      ${!!props.css && props.css}`}
      disabled={props.disabled}
      onClick={
        props.disabled
          ? () => {
              return
            }
          : props.onClick
      }
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </button>
  )
})

export default TextLink
