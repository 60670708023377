/* ===== APP ===== */
export const LOADER = "LOADER"
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE"
/* ===== END APP ===== */

/* ===== AUTH ===== */
export const REQUEST_LOGIN = "REQUEST_LOGIN"
export const SUCCESS_LOGIN = "SUCCESS_LOGIN"
export const FAIL_LOGIN = "FAIL_LOGIN"

export const REQUEST_VERIFY_LOGIN_2FA = "REQUEST_VERIFY_LOGIN_2FA"
export const SUCCESS_VERIFY_LOGIN_2FA = "SUCCESS_VERIFY_LOGIN_2FA"
export const FAIL_VERIFY_LOGIN_2FA = "FAIL_VERIFY_LOGIN_2FA"

export const REQUEST_LOGOUT = "REQUEST_LOGOUT"
export const SUCCESS_LOGOUT = "SUCCESS_LOGOUT"
export const FAIL_LOGOUT = "FAIL_LOGOUT"

export const REQUEST_FORGOT_PASSWORD = "REQUEST_FORGOT_PASSWORD"
export const SUCCESS_FORGOT_PASSWORD = "SUCCESS_FORGOT_PASSWORD"
export const FAIL_FORGOT_PASSWORD = "FAIL_FORGOT_PASSWORD"
export const CLEAR_FORGOT_PASSWORD = "CLEAR_FORGOT_PASSWORD"

export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD"
export const SUCCESS_RESET_PASSWORD = "SUCCESS_RESET_PASSWORD"
export const FAIL_RESET_PASSWORD = "FAIL_RESET_PASSWORD"
export const CLEAR_RESET_PASSWORD = "CLEAR_RESET_PASSWORD"
/* ===== END AUTH ===== */

export const SUBMIT_FORM = "SUBMIT_FORM"
export const SUCCESS_SUBMIT_FORM = "SUCCESS_SUBMIT_FORM"
export const FAIL_SUBMIT_FORM = "FAIL_SUBMIT_FORM"
