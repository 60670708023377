import React from "react"

const Pagination = (props) => {
  const meta = props.meta
  const buttonClass =
    "-24 px-4 py-2 rounded-md bg-fountainBlue text-white text-sm text-center font-semibold cursor-pointer hover:opacity-50"

  return (
    <div>
      {!!meta && meta.total > 1 && (
        <div className="flex justify-center items-center py-10">
          <div
            className={`${buttonClass} ${
              meta.current_page === 1 && "opacity-0 pointer-events-none"
            }`}
            onClick={() => props.goToPage(meta.current_page - 1)}
          >
            Previous
          </div>
          <div className="px-8">
            Page {meta.current_page} of {meta.last_page}
          </div>
          <div
            className={`${buttonClass} ${
              meta.current_page === meta.last_page &&
              "opacity-0 pointer-events-none"
            }`}
            onClick={() => props.goToPage(meta.current_page + 1)}
          >
            Next
          </div>
        </div>
      )}
    </div>
  )
}

export default Pagination
